.loading__spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1000; }
.loading__image-spinner {
  background-image: url('../../../assets/images/Preloader_7.gif');
  background-repeat: no-repeat;
  width: 70px;
  height: 70px; }
