.ratingModuleRatingsContainer {
    display: flex;
    flex-direction: row; }



.ratingModuleRatingContainer {
    position: relative;
    width: 20px;
    height: 20px; }

.ratingModuleRatingStar {
    position: absolute;
    cursor: pointer;
    &:hover {} }
