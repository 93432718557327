.connectYourAccounts {
    min-width: 60%; }
.accountsConnectionTitle {
    color: #707070;
    font-family: Helvetica Neue;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 20px 0; }
.BacktoMainActions {
    margin: 50px 0 0; }
