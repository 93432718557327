
.footerContainer {
    background-color: #f5f5f5;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 20px;
    border-top: 1px solid #e0e0e0; }

.footerContent {
    color: #666;
    display: flex;
    flex-grow: 1;
    flex-direction: column; }

.footerText {
    font-size: .875rem;
    margin: 5px 0;
    font-family: Raleway;
    font-weight: 200; }


.footerVersion {
    margin-top: 10px;
    margin-right: 3rem;
    align-self: flex-end; }
