.clickable {
    cursor: pointer; }

.inputFieldDivRegister {
    display: flex;
    height: 77px;
    flex-direction: column;
    gap: 20px; }

.rowInputFields {
    margin-right: 50px; }

.userDetailsForm {
    display: flex;
    flex-direction: column; }

.nameLastNameContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%; }

.linkText {
    font-family: Arial;
    color: #489dff;
    font-size: 12px;
    line-height: 24px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none; }
.privacyPolicyContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: row; }

.commonText {
    font-family: Inter;
    color: #BABABA;
    font-size: 10px;
    line-height: 14px;
    font-style: normal;
    font-weight: 700; }

.registerFormCheckbox {
    top: 2px;
    position: relative;
    // appearance: none
    // -webkit-appearance: none
    // background-color: #e7e7e7
    // border: 1px solid #cacece
    // box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05)
    // border-radius: 3px
    // margin: 0px 5px 0px 0px
    // padding: 6px
    // display: inline-block
    // position: relative
    // &:checked
    //     background-color: #489dff
    //     border: 1px solid #adb8c0
    //     box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1)
    //     color: #99a1a7
    // &::after
    //     content: '\2714'
    //     font-size: 13px
    //     position: absolute
    //     top: -3px
    //     left: 0px
 }    //     color: #fff

.backSignIn {
    font-family: Arial;
    color: #489dff;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    margin-right: auto !important; }

.reg_btn_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px; }

.input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 34px;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    font-size: 18px;
    padding: 0px;
    line-height: 20px;
    width: 300px;
    &:invalid {
        box-shadow: none; }
    &:focus {
        outline: 0; }
    &:before {} }

.signUpButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 0px;
    padding: 6px 16px;
    font-size: 14px;
    text-transform: capitalize;
    cursor: pointer;
    height: 26px;
    font-family: Arial,sans-serif;
    border-radius: 20px;
    border: 1px solid;
    border-color: #BABABA;
    color: #2196f3;
    background-color: #ffffff;
    box-shadow: none;
    width: max-content; }

@media (min-width: 1400px) {
    .inputFieldDivRegister {
        flex-direction: row; } }

@media (min-width: 790px) and (max-width: 820px) {
    .rightSideDiv {
        width: 70%; } }
@media (max-width: 1399px) {
    .inputFieldDivRegister {
        flex-direction: column;
        height: auto !important;
        gap: 5px;
        margin-top: 5px; }

    .backSignIn {
        margin: 19px 0px 0px auto; }
 }    //Phones, small devices
@media (min-width: 100px) and (max-width: 426px) {

    .inputFieldDivRegister {
        flex-wrap: wrap;
        width: 80%; } }

//Bigger phones
@media (min-width: 426px) and (max-width: 660px) {

    .inputFieldDivRegister {
        flex-wrap: wrap;
        width: 80%; } }

//Bigger phones
@media (min-width: 660px) and (max-width: 768px) {

    .inputFieldDivRegister {
        flex-wrap: wrap;
        width: 80%; } }

//Tablets, medium devices
@media (min-width: 768px) and (max-width: 1024px) {

    .inputFieldDivRegister {
        flex-wrap: wrap;
        width: 80%; } }

//Tablets, medium devices
@media (min-width: 1024px) and (max-width: 1380px) {

    .inputFieldDivRegister {
        flex-wrap: wrap;
        width: 80%; } }

.tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 35%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 10px;

    &:hover .tooltiptext {
        visibility: visible;
        opacity: 1; }

    .pi-input-ele:hover ~  .tooltiptext {
        display: block; } }

.show-password__icon {
    img {
        width: 20px;
        margin-left: -30px;
        margin-top: 8px; } }

.pi-input-skel-block {
    flex: 1;
    min-width: 200px; }

.pi-input {
    justify-content: space-evenly;

    & .pi-label {
        font-size: 13px;
        font-weight: 400;
        color: #707070;
        text-align: right;
        max-width: 100%;
        line-height: 14px; }

    & .pi-input-skel {
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 3px;

        & .pi-input-suggestions {
            position: absolute;
            top: 100%;
            left: 0%;
            width: 100%;
            background-color: #fff;
            z-index: 100;

            & .pi-opt-list {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flow-root;
                max-height: 40vh;
                overflow-y: auto; } }

        & .pi-input-prefix {
            background-color: #DEDEDE;
            border-radius: 4px 0 0 4px;
            border-right: 1px solid #BABABA;
            color: #707070;
            font-size: 12px; }

        & .pi-input-ele {
            font-family: Raleway;
            background-color: #F6F6F6;
            color: #707070;
            padding: 10px;
            border-radius: 0 4px 4px 0;
            border: none;
            font-size: 13px;
            height: 20px;
            width: 100%;
            outline: none; }

        &.pi-input-error-border {
            border: 1px solid #FF0000;
            border-radius: 3px; } } }

// phone field
.custom-phone-input-container.react-tel-input {
    width: 100% !important; }

.custom-phone-input-container .special-label {
    display: none; }

.pi-input-error-message {
    color: #FF0000;
    font-size: 10px;
    margin-left: 51%; }

.pi-input-error-message-sub {
    color: #FF0000;
    font-size: 10px; }

.btn {
    border: 1px solid #A3C830;
    background-color: white;
    padding: 7px 0px;
    font-size: 12px;
    cursor: pointer;
    color: #A3C830; }

.btn:hover {
    background-color: white; }

.Reg-blueButton {
    padding: 8px 18px;
    width: fit-content;
    border-radius: 4px;
    background: #489DFF;
    font-family: Raleway;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border: none;
    &:hover {
        background: #3679c5;
        color: white; } }
