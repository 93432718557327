.third-party-auth-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    text-align: center;

    &__title {
        font-size: 2rem;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            font-size: 1.5rem;
        }
    }

    &__description {
        max-width: 600px;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            font-size: 0.9rem;
        }
    }

    &__user-type {
        font-weight: bold;
        margin-top: 1rem;
    }

    &__manage-tokens-button {
        margin-top: 1rem;
        background-color: orange;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.3s;

        &:hover {
            background-color: darkorange;
            transform: scale(1.05);
        }

        &:active {
            background-color: orangered;
            transform: scale(0.95);
        }
    }
}
