.third-party-auth-scopes {
    &__header {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    &__list {
        list-style-type: none;
        padding: 0;
    }

    &__item {
        margin-bottom: 0.5rem;
    }

    &__label {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__checkbox {
        margin-right: 0.5rem;
    }

    &__scope-text {
        font-size: 1rem;
    }

    &__button {
        width: 60%;
        margin-top: 20px;
        padding: 12px 24px;
        font-size: 16px;
        color: #fff;
        background-color: #489dff;
        border: none;
        border-radius: 4px;
        cursor:pointer;
    }
}
