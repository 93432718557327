.connect-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 20px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    z-index: 1000;
    background-color: transparent;

    &--visible {
        transform: translateY(0);
        opacity: 1;
    }

    &--hidden {
        transform: translateY(100%);
        opacity: 0;
    }

    &__container {
        background-color: white;
        padding: 24px;
        border-radius: 8px;
        max-width: 900px;
        width: 100%;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }
    }

    &__title {
        font-size: 18px;
        margin-bottom: 16px;
        color: #333;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 10px;

        &-logo {
            display: flex;
            align-items: center;
        }
    }

    &__text {
        line-height: 1.5;
        color: #666;
        font-size: 12px;
        width: 70%;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__link {
        color: #007bff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__buttons {
        display: flex;
        gap: 12px;
        justify-content: flex-end;

        @media (max-width: 768px) {
            width: 100%;
            flex-direction: column;
        }
    }

    &__email-link {
        text-decoration: none;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__button {
        padding: 10px 20px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        font-weight: 500;
        transition: background-color 0.2s ease;

        @media (max-width: 768px) {
            width: 100%;
        }

        &--primary {
            background-color: #007bff;
            color: white;

            &:hover {
                background-color: #0056b3;
            }
        }

        &--secondary {
            background-color: #e9ecef;
            color: #333;

            &:hover {
                background-color: #dee2e6;
            }
        }
    }
}
