.formContainer {
    width: 100%;
    display: flex;
    flex-direction: column; }

@media (min-width: 100px) and (max-width: 426px) {
    .loginFormContainer {
        width: 90% !important; } }

@media (min-width: 426px) {
    .loginFormContainer {
        width: 60%; } }
