.passwordStrengthChecker {
    margin-top: 25px; }

.passwordStrengthMeterGreen {
    width: 40px;
    height: 6px;
    border-radius: 3px;
    background-color: #81d78f;
    margin: 5px;
    margin-left: 0px; }

.passwordStrengthMeterRed {
    width: 40px;
    height: 6px;
    border-radius: 3px;
    background-color: #ff3b3b;
    margin: 5px;
    margin-left: 0px; }

.passwordStrengthMeterGray {
    width: 40px;
    height: 6px;
    border-radius: 3px;
    background-color: #dbdbdb;
    margin: 5px;
    margin-left: 0px; }

.commonText {
    font-family: Arial;
    color: #A7A7A7;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    font-weight: 400px; }
