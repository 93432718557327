.tier-picker__backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #00000040;
    z-index: 10;
    display: grid;
    place-items: center;
}

.tier-picker__container {
    
    gap: 20px;
    justify-content: center;
    position: absolute;
    background-color: white;
    z-index: 11;
    border: 1px solid #dedede;
    border-radius: 4px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    width: 1000px;
    height: 550px;
    padding: 30px 20px 20px 20px;

    .tier-picker__tier-card {
        background-color: #f6f5f3;
        border: 2px solid #d7d9dc;
        width: 100%;
        height: 80%;
        border-radius: 8px;
        padding: 10px;
        cursor: pointer;
    }

    .tier-picker__container__title {
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 22px;
        color: #707070;
        margin-bottom: 50px;
    }

    .tier-card__title {
        font-weight: 700;
        font-size: 18px;
        font-family: Inter;
        font-style: normal;
        color: #6f6f6f;
        text-align: center;
        margin-top: 8px;
        margin-bottom: 20px;
    }

    .tier-card__price {
        font-weight: 700;
        font-size: 16px;
        margin-top: 0px;
        font-family: Inter;
        font-style: normal;
        color: #489dff;
        margin-bottom: 20px;
    }

    .features__list {
        list-style: none;
        color: #bababa;
        padding-left: 0;

        li {
            list-style: none;
            margin-top: 8px;
            margin-bottom: 8px;
        }

        .main-feature {
            //color: #6f6f6f;
        }
    }

    .tier-card__selected {
        border: 2px solid rgb(178, 218, 93);
        box-shadow: 0 0 10px rgba(122, 161, 38, 0.5);
    }
}

.continueButton {
    transform: translateX(430%) translateY(250px);
    z-index: 12;
    display: inline-flex;
    padding: 8px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Label-text, #489DFF);
    color: white;
    cursor: pointer;
}

.tier-picker__backdrop {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.tier-picker__backdrop.show {
    opacity: 1;

}

.bullet-point {
    margin-right: 5px;
    color: rgba(98, 144, 0, 0.5);
}

.tier-picker__container__tier-cards {
    display: flex;
    gap: 20px;
    justify-content: center;
    height: 460px;
}

.most-popular-tag {
    font-size: 0.8rem;
    color: #4CAF50;
    margin-left: 8px;
    font-weight: 500;
}