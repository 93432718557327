.validationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    min-width: 60%; }

.validationHeader {
    font-family: Helvetica, sans-serif;
    text-align: center;
    > p {
        color: #707070;
        font-size: 0.875rem;
        font-weight: 400; } }

.validationTitle {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 12px;
    color: #3d3b3b; }


// Validation Form Styles
.validationWrapper {
    display: inline-flex;
    gap: 0.5rem; }

.validationInput {
    width: 2rem;
    height: auto;
    border-radius: 0.25rem;
    border: solid 1px #5f5e5e;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0 , 0.1), 0 1px 2px -1px rgba(0,0, 0 , 0.1);
    padding: 0.5rem;
    text-align: center;
    font-size: 1.5rem; }

.validationBtn {
    min-width: 7rem;
    max-width: 10rem;
    width: 100%;
    min-height: 2.5rem;
    border: none;
    background-color: #3E7BFA;
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s ease-in;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0 , 0.1), 0 1px 2px -1px rgba(0,0, 0 , 0.1);


    &:hover {
        background-color: #2455be; } }

.textError {
    color: #ec510f;
    // font-size: 0.875rem
 }    // font-weight: 400

.shield {
    font-size: 3rem;
    font-weight: 900;
    color: #14be74; }

.validationInvalid {
    text-align: center;
    margin-top: 5rem;
    padding: 10rem; }

.validationInvalidText {
    color: #707070;
    font-size: 0.875rem;
    font-weight: 400; }

//Phones, small devices
@media (min-width: 100px) and (max-width: 426px) {
   .validationInvalid {
        padding: 0rem;
 } }        // margin-left: 10px

//Bigger phones
@media (min-width: 426px) and (max-width: 660px) {
    .validationInvalid {
        padding: 2rem; } }


//Bigger phones
@media (min-width: 660px) and (max-width: 768px) {
   .validationInvalid {
        padding: 6rem; } }



//Tablets, medium devices
@media (min-width: 768px) and (max-width: 1024px) {
    .validationInvalid {
        padding: 6rem; } }

//Tablets, medium devices
@media (min-width: 1024px) and (max-width: 1380px) {
    .validationInvalid {
        padding: 6rem; } }
