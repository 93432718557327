.third-party-auth {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem;
    background-color: #f0f2f5;

    &__container {
        width: 100%;
        max-width: 400px;
        padding: 2rem;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &__title {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    &__Maintitle {
        font-size: 1.2rem;
        text-align: center;
        margin-bottom: 1.5rem;
        color: #777;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__input {
        padding: 0.75rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
    }

    &__button {
        padding: 0.75rem;
        border: none;
        border-radius: 4px;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &--primary {
            background-color: #489dff;
            color: #ffffff;

            &:hover {
                background-color: #408ce4;
            }
        }

        &--auth0 {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            padding: 10px 20px;
            border: 2px solid #ec521d;
            background-color: #fdbba4;
            border-radius: 4px;
            cursor: pointer;
            color: #d15127;

            &:hover {
                background-color: #fdad90;
            }
        }
    }

    &__separator {
        display: flex;
        align-items: center;
        text-align: center;
        margin: 1rem 0;

        &::before,
        &::after {
            content: "";
            flex: 1;
            border-bottom: 1px solid #ccc;
        }

        span {
            padding: 0 0.5rem;
            color: #777;
        }
    }

    &__error {
        color: #ff0000;
        text-align: center;
    }
}

.third-party-auth__toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    span {
        margin: 0 10px;
        font-size: 14px;
    }
}

.third-party-auth__toggle {
    position: relative;
    display: inline-block;
    width: 66px;
    height: 24px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .third-party-auth__toggle-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.3s;
        border-radius: 24px;

        &:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: 3px;
            bottom: 3px;
            background-color: white;
            transition: 0.3s;
            border-radius: 50%;
        }
    }

    input:checked + .third-party-auth__toggle-slider {
        background-color: #2196f3;
    }

    input:checked + .third-party-auth__toggle-slider:before {
        transform: translateX(22px);
    }
}
