.clickableIcon {
    cursor: pointer; }

.loginFormContainer {
    width: 90%;
    justify-content: center;
    align-items: center; }

.inputFieldDiv {
    margin-bottom: 5px;
    width: 350px;
    flex-direction: row; }

.rowInputFields {
    margin-right: 50px; }

.signInButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 0px;
    padding: 6px 16px;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    //witdh: 60%
    height: 26px;
    font-family: Arial,sans-serif;
    border-radius: 20px;
    border: 1px solid;
    border-color: #BABABA;
    color: #2196f3;
    background-color: #ffffff;
    box-shadow: none; }

.link-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32%; }

.goToRegisterLink {
    margin-right: auto;
    color: #489DFF;
    font-family: Arial, sans-serif;
    font-size: 14px;
    float: left; }

.commonText {
    font-family: Inter;
    color: #BABABA !important;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    font-weight: 400; }

.loginFormLabel {
    margin: 0;
    font-size: 12px;
    color: #a7a7a7;
    margin-bottom: 0px;
    line-height: 20px;
    padding: 0;
    font-style: normal;
    font-weight: 400; }

.login-passwordResetLinkdiv {
    margin-bottom: 10px; }

.input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    font-size: 18px;
    padding: 0px;
    line-height: 20px;
    width: 300px;
    &:invalid {
        box-shadow: none; }
    &:focus {
        outline: 0; }
    &:before {} }

.input__error {
    font-size: 12px !important;
    color: #ff3b3b; }

.swal-title {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 700;
    line-height: 25.18px; }
.swal-html-container {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px; }
@media (max-width: 790px) {
    .rightSideDiv {
        width: 78%!important; } }

.login_blueButton {
    padding: 8px 18px;
    width: fit-content;
    border-radius: 4px;
    background: #489DFF;
    font-family: Raleway;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border: none;
    &:hover {
        background: #3679c5;
        color: white; } }
