@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
.clickableIcon {
    cursor: pointer; }

.loginFormContainer {
    width: 90%;
    justify-content: center;
    align-items: center; }


.rowInputFields {
    margin-right: 50px; }
.resetPasswordButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 0px;
    padding: 6px 16px;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    //witdh: 60%
    height: 26px;
    font-family: Arial,sans-serif;
    border-radius: 20px;
    border: 1px solid;
    border-color: #BABABA;
    color: #2196f3;
    background-color: #ffffff;
    box-shadow: none; }

.reset_btn_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 350px;
    margin-top: 25px; }

.commonText {
    font-family: Arial;
    color: #a7a7a7;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    font-weight: 400; }
h6 {
    margin: 0;
    font-size: 12px;
    color: #a7a7a7;
    margin-bottom: 0px;
    line-height: 20px;
    padding: 0;
    font-style: normal;
    font-weight: 400; }

.passwordResetLinkdiv {
    margin-top: 10px;
    margin-bottom: 10px; }

.loginFormContainer {
    width: 90%;
    justify-content: center;
    align-items: center; }

.commonText {
    font-family: Arial;
    color: #a7a7a7;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
    font-weight: 400; }
h6 {
    margin: 0;
    font-size: 12px;
    color: #a7a7a7;
    margin-bottom: 0px;
    line-height: 20px;
    padding: 0;
    font-style: normal;
    font-weight: 400; }

.passwordResetLinkdiv {
    margin-top: 10px;
    margin-bottom: 10px; }
.otpDiv {
    margin-top: 50px; }

.input {
    font-family: Comfortaa;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 34px;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    font-size: 18px;
    padding: 0px;
    line-height: 20px;
    width: 300px;
    &:invalid {
        box-shadow: none; }
    &:focus {
        outline: 0; }
    &:before {} }

.pi-input-skel-block {
    flex: 1;
    min-width: 200px; }


.pi-input {
    justify-content: space-evenly;

    & .pi-label {
        font-size: 13px;
        font-weight: 400;
        color: #707070;
        text-align: right;
        max-width: 100%;
        line-height: 14px; }


    & .pi-input-skel {
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 3px;

        & .pi-input-suggestions {
            position: absolute;
            top: 100%;
            left: 0%;
            width: 100%;
            background-color: #fff;
            z-index: 100;

            & .pi-opt-list {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flow-root;
                max-height: 40vh;
                overflow-y: auto; } }

        & .pi-input-prefix {
            background-color: #DEDEDE;
            border-radius: 4px 0 0 4px;
            border-right: 1px solid #BABABA;
            color: #707070;
            font-size: 12px; }


        & .pi-input-ele {
            font-family: Raleway;
            background-color: #F6F6F6;
            color: #707070;
            padding: 10px;
            border-radius: 0 4px 4px 0;
            border: none;
            font-size: 13px;
            height: 20px;
            width: 100%;
            outline: none; }


        &.pi-input-error-border {
            border: 1px solid #FF0000;
            border-radius: 3px; }

        .tooltiptext {
            visibility: hidden;
            width: 220px;
            background-color: #555;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 10px;
            position: absolute;
            z-index: 1;
            bottom: 125%;
            left: 35%;
            margin-left: -60px;
            opacity: 0;
            transition: opacity 0.3s;
            font-size: 10px; }

        &:hover .tooltiptext {
            visibility: visible;
            opacity: 1; }

        .pi-input-ele:hover ~  .tooltiptext {
            display: block; } } }

// phone field
.custom-phone-input-container.react-tel-input {
    width: 100% !important; }


.custom-phone-input-container .special-label {
    display: none; }


.pi-input-error-message {
    color: #FF0000;
    font-size: 10px;
    margin-left: 51%; }


.pi-input-error-message-sub {
    color: #FF0000;
    font-size: 10px; }


.btn {
    border: 1px solid #A3C830;
    background-color: white;
    padding: 7px 0px;
    font-size: 12px;
    cursor: pointer;
    color: #A3C830; }


.btn:hover {
    background-color: white; }

.reset_blueButton {
    padding: 8px 18px;
    width: fit-content;
    border-radius: 4px;
    background: #489DFF;
    font-family: Raleway;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border: none;
    &:hover {
        background: #3679c5;
        color: white; } }
