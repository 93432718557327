.termsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px 20px 20px;
    background-color: #FFFFFF;
    font-family: Raleway;

    .contentContainer {
        width: 100%;
        background: #FFFFFF;
        padding: 40px;
        border-radius: 12px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        margin: 20px 0; }

    .termsContent {
        margin-top: 20px;
        font-family: Inter;
        font-size: 14px;
        line-height: 1.6;
        color: #707070;
        padding: 24px;
        background: #FFFFFF;
        border: 1px solid #BABABA;
        border-radius: 8px;

        .lastUpdated {
            font-family: Inter;
            color: #BABABA;
            font-size: 12px;
            line-height: 14px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 16px; }

        .terms-text {
            margin-bottom: 16px;
            line-height: 1.5;
            font-size: 14px;
            color: #707070;
            white-space: pre-line; }

        .footer {
            font-family: Inter;
            color: #BABABA;
            font-size: 12px;
            line-height: 14px;
            font-style: normal;
            font-weight: 400;
            margin-top: 24px; } } }

@media (max-width: 768px) {
    .termsContainer {
        padding: 10px;

        .contentContainer {
            padding: 20px;
            margin: 10px 0; }

        .termsContent {
            padding: 16px;
            font-size: 13px;

            .terms-text {
                font-size: 16px; } } } }




.term-point {
    margin-bottom: 1rem;
    line-height: 1.5; }
