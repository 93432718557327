.patientSurveyContainer {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 20px auto;
    padding: 0 25px; }

.patientSurveyHeader {
    margin: 50px 0px; }

.patientSurveyTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }

.patientSurveyHeaderContent {
    flex-direction: column; }

.patientSurveyHeaderText {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    color: #707070; }

.patientSurveyHeaderQuestions {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #BABABA; }

.patientSurveyHeaderDate {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #489DFF; }

.patientSurveyQuestionContainer {
    display: flex;
    flex-direciton: row; }

.patientSurveyQuestion {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background-color: #489dff;
    margin-right: 10px; }

.patientSurveryQuestionNumber {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #fff; }

.patientSurveyQuestionAnswer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px; }

.patientSurveyButtons {
    display: flex;
    gap: 20px;
    margin-left: 35px; }

.patientSurveyQuestionText {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #BABABA; }

.error-msg {
    padding: 0; }

.carexLogoContainer {
    margin-top: 30px;
    margin-bottom: 20px;
    display: block; }

.carexLogoContainer1 {
    margin-top: 30px;
    margin-left: 80px; }

.carexLogo {
    max-width: 120px;
    max-height: 120px;
    margin: 0; }

.carexLogoText {
    font-family: Roboto, sans-serif;
    color: #707070;
    font-size: 14px;
    font-weight: 400; }

@media (min-width: 100px) and (max-width: 660px) {
    .cexInputArea {
        width: 95%!important;
        max-width: 100%; } }
