.actual-tokens {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    &__content {
        background-color: white;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        max-width: 90%;
        width: 90%;
        max-height: 90vh;
        overflow-y: scroll;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }

    &__title {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }
}

.tokens-table {
    &__row {
        display: grid;
        grid-template-columns: 0.5fr 2fr 1.5fr 1fr 0.8fr 40px;
        gap: 1rem;
        padding: 1rem;
        border: 1px solid #eee;
        margin-bottom: 0.5rem;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
            background-color: #f8f9fa;
            transform: translateY(-1px);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        }

        &.expanded {
            background-color: #f8f9fa;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            margin-bottom: 0;
        }
    }

    &__cell {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #444;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.id {
            font-weight: 600;
        }

        &.token {
            color: #666;
        }

        &.status {
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 500;
            width: fit-content;

            &.active {
                background-color: #e6f4ea;
                color: #1e7e34;
            }

            &.inactive {
                background-color: #feeced;
                color: #dc3545;
            }
        }
    }

    &__cell-arrow {
        display: flex;
        align-items: center;
        justify-content: center;

        .arrow {
            transition: transform 0.2s ease;
            font-size: 12px;
            color: #666;

            &.up {
                transform: rotate(180deg);
            }
        }
    }
}

.expanded-row {
    background-color: #f8f9fa;
    padding: 1rem;
    border: 1px solid #eee;
    border-top: none;
    margin-bottom: 0.5rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1rem;
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__delete-button {
        background-color: rgb(254, 214, 214);
        border: 1px solid #dc3545;
        color: #dc3545;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        padding: 0.8rem 0rem;
    }

    &__label {
        font-size: 12px;
        color: #666;
        font-weight: 500;
    }

    &__value {
        font-size: 14px;
        color: #444;
        word-break: break-all;
    }
}

@media (max-width: 768px) {
    .tokens-table {
        &__row {
            grid-template-columns: 0.5fr 1.5fr 1fr 40px;
            gap: 0.5rem;
        }

        &__cell {
            &.scopes {
                display: none;
            }
        }
    }

    .expanded-row {
        &__grid {
            grid-template-columns: 1fr;
        }
    }
}
