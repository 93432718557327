.third-party-auth-table {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    color: #333;

    &__header {
        font-size: 28px;
        margin-bottom: 20px;
        color: #489dff;
    }

    &__search {
        margin-bottom: 20px;
        width: 60%;
    }

    &__search-input {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        border: 2px solid #489dff;
        border-radius: 4px;
        transition: border-color 0.3s ease;

        &:focus {
            outline: none;
            border-color: #a3c830;
        }
    }

    &__table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        table-layout: fixed;
    }

    &__th {
        background-color: #489dff;
        color: white;
        padding: 15px;
        text-align: left;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            background-color: darken(#489dff, 10%);
        }
    }

    &__td {
        padding: 12px 15px;
        border-bottom: 1px solid #ddd;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align-last: left;
    }

    &__tr {
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #f0f8ff;
        }

        &:last-child {
            .third-party-auth-table__td {
                border-bottom: none;
            }
        }
    }

    &__checkbox {
        cursor: pointer;
        width: 18px;
        height: 18px;
    }

    &__selected-patients {
        margin-top: 20px;
        font-size: 16px;
        color: #489dff;
    }

    &__name-column {
        width: 40%;
    }

    &__lastname-column {
        width: 40%;
    }

    &__select-column {
        width: 20%;
    }

    &__message {
        text-align: center;
        padding: 20px;
        font-size: 18px;
        font-weight: bold;
        border-bottom: 1px solid #ddd;

        &--loading {
            color: #489dff;
            background-color: #f0f8ff;
        }

        &--no-results {
            color: #a3c830;
            background-color: #f8fff0;
        }
    }

    &__button {
        margin-top: 20px;
        padding: 12px 24px;
        font-size: 16px;
        color: #fff;
        background-color: #489dff;
        border: none;
        border-radius: 4px;
        width: 30%;
    }
}
